<template>
	<NuxtLayout name="default">
		<section class="max-w-3xl container text-center py-8 sm:pt-12 sm:pb-16">
			<h1 class="mt-4 text-4xl">
				{{ error.statusCode }}
			</h1>
			<picture>
				<source type="image/webp" srcset="~/assets/img/error-robot.webp" />
				<img
					class="error-robot mb-4 inline"
					style="height: 150px"
					src="~/assets/img/error-robot.png"
				/>
			</picture>
			<div class="mb-10">
				<h2>
					{{ message }}
				</h2>
				<span v-if="error.statusCode === 404">{{ $route.fullPath }}</span>
			</div>
		</section>
	</NuxtLayout>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import type { PropType } from 'vue';

export default defineComponent({
	name: 'ErrorLayout',
	computed: {
		message() {
			let title = '';

			if (this.error.statusCode >= 500) {
				title = 'Serverfehler: ';
			}

			return (
				title +
				((this.error?.statusCode >= 500 &&
					(this.error.message || 'Serverfehler, bitte versuch es noch einmal')) ||
					(this.error.response?.data?.name &&
						`${this.error.response?.data?.name} ${this.error.response?.data?.message}`) ||
					this.error.message ||
					'Error')
			);
		}
	},
	props: {
		error: { type: Object as PropType<any>, default: () => {} }
	}
});
</script>
