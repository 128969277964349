import { default as countryselectionutqWVANRhcMeta } from "/home/runner/work/hokify/hokify/apps/international-page-app/src/pages/countryselection.vue?macro=true";
import { default as _403_userextrasT3ApdpflTnMeta } from "/home/runner/work/hokify/hokify/apps/international-page-app/src/pages/errors/403_userextras.vue?macro=true";
import { default as _403iu0t4jyHulMeta } from "/home/runner/work/hokify/hokify/apps/international-page-app/src/pages/errors/403.vue?macro=true";
import { default as _404eW4Kmh6yLCMeta } from "/home/runner/work/hokify/hokify/apps/international-page-app/src/pages/errors/404.vue?macro=true";
import { default as indexojboaTabvgMeta } from "/home/runner/work/hokify/hokify/apps/international-page-app/src/pages/index.vue?macro=true";
export default [
  {
    name: countryselectionutqWVANRhcMeta?.name ?? "countryselection",
    path: countryselectionutqWVANRhcMeta?.path ?? "/countryselection",
    meta: countryselectionutqWVANRhcMeta || {},
    alias: countryselectionutqWVANRhcMeta?.alias || [],
    redirect: countryselectionutqWVANRhcMeta?.redirect,
    component: () => import("/home/runner/work/hokify/hokify/apps/international-page-app/src/pages/countryselection.vue").then(m => m.default || m)
  },
  {
    name: _403_userextrasT3ApdpflTnMeta?.name ?? "errors-403_userextras",
    path: _403_userextrasT3ApdpflTnMeta?.path ?? "/errors/403_userextras",
    meta: _403_userextrasT3ApdpflTnMeta || {},
    alias: _403_userextrasT3ApdpflTnMeta?.alias || [],
    redirect: _403_userextrasT3ApdpflTnMeta?.redirect,
    component: () => import("/home/runner/work/hokify/hokify/apps/international-page-app/src/pages/errors/403_userextras.vue").then(m => m.default || m)
  },
  {
    name: _403iu0t4jyHulMeta?.name ?? "errors-403",
    path: _403iu0t4jyHulMeta?.path ?? "/errors/403",
    meta: _403iu0t4jyHulMeta || {},
    alias: _403iu0t4jyHulMeta?.alias || [],
    redirect: _403iu0t4jyHulMeta?.redirect,
    component: () => import("/home/runner/work/hokify/hokify/apps/international-page-app/src/pages/errors/403.vue").then(m => m.default || m)
  },
  {
    name: _404eW4Kmh6yLCMeta?.name ?? "errors-404",
    path: _404eW4Kmh6yLCMeta?.path ?? "/errors/404",
    meta: _404eW4Kmh6yLCMeta || {},
    alias: _404eW4Kmh6yLCMeta?.alias || [],
    redirect: _404eW4Kmh6yLCMeta?.redirect,
    component: () => import("/home/runner/work/hokify/hokify/apps/international-page-app/src/pages/errors/404.vue").then(m => m.default || m)
  },
  {
    name: indexojboaTabvgMeta?.name ?? "index",
    path: indexojboaTabvgMeta?.path ?? "/",
    meta: indexojboaTabvgMeta || {},
    alias: indexojboaTabvgMeta?.alias || [],
    redirect: indexojboaTabvgMeta?.redirect,
    component: () => import("/home/runner/work/hokify/hokify/apps/international-page-app/src/pages/index.vue").then(m => m.default || m)
  }
]