<template>
	<main>
		<section class="max-w-3xl container text-center py-8 sm:pt-12 sm:pb-16">
			<h1 class="mt-4 text-4xl">403 Access Denied</h1>
			<picture>
				<source type="image/webp" srcset="~/assets/img/error-robot.webp" />
				<img
					class="error-robot mb-4 inline"
					style="height: 150px"
					src="~/assets/img/error-robot.png"
				/>
			</picture>
			<div class="mb-10">
				<h2>Der Zugriff auf diese Ressource ist nicht erlaubt</h2>
				<span>{{ $route.fullPath }}</span>
			</div>
		</section>
	</main>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'Error403'
});
</script>
