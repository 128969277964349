<template>
	<img
		:key="`icon-${hashCode(iconKey)}`"
		:alt="`icon-${icon}`"
		:src="getAssetSrc(icon)"
		:data-icon="icon"
		:class="myclass"
	/>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'HokIcon',
	data() {
		const component = undefined as undefined | object;

		return { component };
	},
	computed: {
		iconKey() {
			return this.icon + this.size + this.color + this.verticalAlign + this.pointer;
		},
		myclass() {
			const myclass: string[] = [
				// TODO: check what for was this + do we still need it?
				// process.server && this.$vnode.data?.class, // for ssr
				// process.server && this.$vnode.data?.staticClass, // for ssr
				'icon',
				this.icon.replace(/-\d$/, '')
			];

			if (this.pointer) {
				myclass.push('cursor-pointer');
			}

			myclass.push(this.verticalAlign);

			switch (this.size) {
				case 0:
					myclass.push('w-0 h-0');
					break;
				case 1:
					myclass.push('w-1 h-1');
					break;
				case 2:
					myclass.push('w-2 h-2');
					break;
				case 3:
					myclass.push('w-3 h-3');
					break;
				case 4:
					myclass.push('w-4 h-4');
					break;
				case 5:
					myclass.push('w-5 h-5');
					break;
				case 6:
					myclass.push('w-6 h-6');
					break;
				case 7:
					myclass.push('w-7 h-7');
					break;
				case 8:
					myclass.push('w-8 h-8');
					break;
				case 10:
					myclass.push('w-10 h-10');
					break;
				case 12:
					myclass.push('w-12 h-12');
					break;
				case 14:
					myclass.push('w-14 h-14');
					break;
				case 16:
					myclass.push('w-16 h-16');
					break;
				case 20:
					myclass.push('w-20 h-20');
					break;
				case 24:
					myclass.push('w-24 h-24');
					break;
				case 32:
					myclass.push('w-32 h-32');
					break;
				case 40:
					myclass.push('w-40 h-40');
					break;
				case 48:
					myclass.push('w-48 h-48');
					break;
				case 56:
					myclass.push('w-56 h-56');
					break;
				case 64:
					myclass.push('w-64 h-64');
					break;
				default:
				// nothing!
			}

			switch (this.color) {
				case 'blue':
					myclass.push('text-color-blue');
					break;
				case 'yellow':
					myclass.push('text-color-yellow');
					break;
				case 'main':
					myclass.push('text-color-main');
					break;
				case 'main-business':
					myclass.push('text-color-main-business');
					break;
				case 'purple':
					myclass.push('text-color-purple');
					break;
				case 'grey':
					myclass.push('text-color-grey');
					break;
				case 'white':
					myclass.push('text-color-white');
					break;
				case 'blue-grey':
					myclass.push('text-color-blue-grey');
					break;
				case 'text':
					myclass.push('text-color-text');
					break;
				case 'grey-light':
					myclass.push('text-color-grey-light');
					break;
				case 'grey-medium':
					myclass.push('text-color-grey-medium');
					break;
				case 'coral':
					myclass.push('text-color-coral');
					break;
				default:
				// nothing
			}

			return myclass;
		}
	},
	methods: {
		// do we want to access shared-components instead?
		getAssetSrc(name: string) {
			const modulePathToUse = `assets/svgs/icons/${name}.svg`;
			// https://vitejs.dev/guide/features#glob-import
			const modules = import.meta.glob('../../assets/svgs/icons/*', { eager: true }) as Record<
				string,
				{ default: string }
			>;
			const moduleEntry = Object.entries(modules).find(([modulePath, _module]) =>
				modulePath.includes(modulePathToUse)
			);
			const [_modulePath, module] = moduleEntry!;
			return module.default;
		},
		hashCode(s) {
			if (!s) {
				return '';
			}
			let h = 0;
			for (let i = 0; i < s.length; i += 1) {
				// eslint-disable-next-line no-bitwise
				h = (Math.imul(31, h) + s.charCodeAt(i)) | 0;
			}

			return h.toString(16);
		},
		onIconChanged() {
			this.component = undefined;
		},
		onStylingChanged() {
			this.component = undefined;
		},
		onColorChange() {
			this.component = undefined;
		}
	},
	props: {
		icon: { type: String, required: true },
		pointer: { type: Boolean, default: false },
		size: {
			type: Number,
			default: 6,
			validator: (value: number) =>
				[0, 1, 2, 3, 4, 5, 6, 7, 8, 10, 12, 14, 16, 20, 24, 32, 40, 48, 56, 64].includes(value)
		},
		color: {
			type: String,
			default: 'default',
			validator: (value: string) =>
				[
					'blue',
					'yellow',
					'main',
					'purple',
					'main-business',
					'grey',
					'white',
					'blue-grey',
					'text',
					'grey-light',
					'grey-medium',
					'coral',
					'default'
				].includes(value)
		},
		verticalAlign: {
			type: String,
			default: 'align-middle',
			validator: (value: string) =>
				[
					'align-middle',
					'align-baseline',
					'align-top',
					'align-bottom',
					'align-text-top',
					'align-text-bottom'
				].includes(value)
		}
	},
	watch: {
		icon: [
			{
				handler: 'onIconChanged'
			}
		],
		styling: [
			{
				handler: 'onStylingChanged'
			}
		],
		color: [
			{
				handler: 'onColorChange'
			}
		]
	}
});
</script>
