export default defineNuxtPlugin(async nuxtApp => {
	const {
		public: { sentry: config }
	} = useRuntimeConfig();

	if (config.disabled) {
		return;
	}

	if (!config.dsn) {
		return;
	}

	let SentrySdk: any;
	let delayedGlobalErrors: [
		event: Event | string,
		source?: string,
		lineno?: number,
		colno?: number,
		error?: Error
	][] = [];
	let delayedUnhandledRejections: PromiseRejectionEvent[] = [];
	let delayedCalls: { method: string; params: unknown }[] = [];

	const delayGlobalError = event => {
		delayedGlobalErrors.push([
			event.message,
			event.filename,
			event.lineno,
			event.colno,
			event.error
		]);
	};

	const delayUnhandledRejection = event => {
		if ('reason' in event && event.reason) {
			event = event.reason;
		} else if (
			'detail' in event &&
			event.detail &&
			'reason' in event.detail &&
			event.detail.reason
		) {
			event = event.detail.reason;
		}
		delayedUnhandledRejections.push(event);
	};

	window.addEventListener('error', delayGlobalError);
	window.addEventListener('unhandledrejection', delayUnhandledRejection);

	const sentryInitialized = async Sentry => {
		window.removeEventListener('error', delayGlobalError);
		window.removeEventListener('unhandledrejection', delayUnhandledRejection);
		if (delayedGlobalErrors.length) {
			if (window.onerror) {
				console.info('Reposting global errors after Sentry has loaded');
				for (const errorArgs of delayedGlobalErrors) {
					// eslint-disable-next-line prefer-spread
					window.onerror.apply(window, errorArgs);
				}
			}
			delayedGlobalErrors = [];
		}
		if (delayedUnhandledRejections.length) {
			if (window.onunhandledrejection) {
				console.info('Reposting unhandled promise rejection errors after Sentry has loaded');
				for (const reason of delayedUnhandledRejections) {
					window.onunhandledrejection(reason);
				}
			}
			delayedUnhandledRejections = [];
		}

		delayedCalls.forEach(({ method, params }) => {
			// eslint-disable-next-line prefer-spread
			Sentry[method].apply(Sentry, params);
		});
		delayedCalls = [];
	};

	const initSentry = async () => {
		const Sentry = await import('@sentry/vue');

		Sentry.init({
			app: nuxtApp.vueApp,
			dsn: config.dsn,
			environment: config.environment,
			release: config.release,
			integrations: [Sentry.browserTracingIntegration()],
			tracesSampleRate: config.tracesSampleRate,
			tracePropagationTargets: config.tracePropagationTargets
		});

		SentrySdk = Sentry;

		await sentryInitialized(Sentry);
	};

	if (config.lazy) {
		onNuxtReady(async () => {
			await new Promise(r => {
				setTimeout(r, 5000);
			});
			await initSentry();
		});
	} else {
		await initSentry();
	}

	const provideSentryHandler = new Proxy(
		{},
		{
			get(_target, method: string) {
				return (...params) => {
					if (SentrySdk && !SentrySdk[method]) {
						console.warn(`SentrySdk method ${method} is not supported`);
						return;
					}
					if (SentrySdk) {
						// eslint-disable-next-line prefer-spread
						SentrySdk[method].apply(SentrySdk, params);
						return;
					}

					delayedCalls.push({ method, params });
				};
			}
		}
	);

	return {
		provide: {
			sentry: provideSentryHandler
		}
	};
});
